// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-layouts-blog-post-js": () => import("/opt/build/repo/src/layouts/blog-post.js" /* webpackChunkName: "component---src-layouts-blog-post-js" */),
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("/opt/build/repo/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-about-process-tsx": () => import("/opt/build/repo/src/pages/about/Process.tsx" /* webpackChunkName: "component---src-pages-about-process-tsx" */),
  "component---src-pages-about-services-tsx": () => import("/opt/build/repo/src/pages/about/Services.tsx" /* webpackChunkName: "component---src-pages-about-services-tsx" */),
  "component---src-pages-about-why-tsx": () => import("/opt/build/repo/src/pages/about/Why.tsx" /* webpackChunkName: "component---src-pages-about-why-tsx" */),
  "component---src-pages-common-footer-tsx": () => import("/opt/build/repo/src/pages/common/footer.tsx" /* webpackChunkName: "component---src-pages-common-footer-tsx" */),
  "component---src-pages-common-header-tsx": () => import("/opt/build/repo/src/pages/common/header.tsx" /* webpackChunkName: "component---src-pages-common-header-tsx" */),
  "component---src-pages-common-heroshot-tsx": () => import("/opt/build/repo/src/pages/common/heroshot.tsx" /* webpackChunkName: "component---src-pages-common-heroshot-tsx" */),
  "component---src-pages-contact-tsx": () => import("/opt/build/repo/src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-ecommerce-tsx": () => import("/opt/build/repo/src/pages/ecommerce.tsx" /* webpackChunkName: "component---src-pages-ecommerce-tsx" */),
  "component---src-pages-es-ecommerce-tsx": () => import("/opt/build/repo/src/pages/es/ecommerce.tsx" /* webpackChunkName: "component---src-pages-es-ecommerce-tsx" */),
  "component---src-pages-es-index-tsx": () => import("/opt/build/repo/src/pages/es/index.tsx" /* webpackChunkName: "component---src-pages-es-index-tsx" */),
  "component---src-pages-homepage-contact-tsx": () => import("/opt/build/repo/src/pages/homepage/Contact.tsx" /* webpackChunkName: "component---src-pages-homepage-contact-tsx" */),
  "component---src-pages-homepage-contact-form-tsx": () => import("/opt/build/repo/src/pages/homepage/ContactForm.tsx" /* webpackChunkName: "component---src-pages-homepage-contact-form-tsx" */),
  "component---src-pages-homepage-customers-tsx": () => import("/opt/build/repo/src/pages/homepage/Customers.tsx" /* webpackChunkName: "component---src-pages-homepage-customers-tsx" */),
  "component---src-pages-homepage-offering-tsx": () => import("/opt/build/repo/src/pages/homepage/Offering.tsx" /* webpackChunkName: "component---src-pages-homepage-offering-tsx" */),
  "component---src-pages-homepage-promise-tsx": () => import("/opt/build/repo/src/pages/homepage/Promise.tsx" /* webpackChunkName: "component---src-pages-homepage-promise-tsx" */),
  "component---src-pages-homepage-services-tsx": () => import("/opt/build/repo/src/pages/homepage/Services.tsx" /* webpackChunkName: "component---src-pages-homepage-services-tsx" */),
  "component---src-pages-homepage-tagline-tsx": () => import("/opt/build/repo/src/pages/homepage/Tagline.tsx" /* webpackChunkName: "component---src-pages-homepage-tagline-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-insights-tsx": () => import("/opt/build/repo/src/pages/insights.tsx" /* webpackChunkName: "component---src-pages-insights-tsx" */),
  "component---src-pages-resources-white-papers-lead-capture-form-tsx": () => import("/opt/build/repo/src/pages/resources/white-papers/LeadCaptureForm.tsx" /* webpackChunkName: "component---src-pages-resources-white-papers-lead-capture-form-tsx" */),
  "component---src-pages-resources-white-papers-product-design-process-guide-tsx": () => import("/opt/build/repo/src/pages/resources/white-papers/product-design-process-guide.tsx" /* webpackChunkName: "component---src-pages-resources-white-papers-product-design-process-guide-tsx" */),
  "component---src-pages-work-tsx": () => import("/opt/build/repo/src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */),
  "component---src-pages-work-grid-tsx": () => import("/opt/build/repo/src/pages/work/Grid.tsx" /* webpackChunkName: "component---src-pages-work-grid-tsx" */),
  "component---src-pages-work-project-tsx": () => import("/opt/build/repo/src/pages/work/Project.tsx" /* webpackChunkName: "component---src-pages-work-project-tsx" */)
}

